@import "../../../../theme/colors/index.scss";
@import "../../../../theme/fonts/index.scss";

.hardware {
  // width: 100%;
 
  h1 {
    font-size: $fontSizeForSubHeading;
    font-weight: $fontWeightForSubHeading;
  }
  .steppers {
    margin: 12px 0px;
    background-color: $mainBgColor;
    border-radius: 8px;
    padding: 16px 0px !important;
    .contents div {
      padding: 4px 16px;
      margin: 8px 0px !important;
      

    }
    .trackNumber {
      color: $buttonColor;
      text-decoration: underline;
      cursor: pointer;
    }

    .css-n7tliy-MuiStep-root,
    .css-166ciyp,
    .MuiStep-root .MuiStep-horizontal .MuiStep-alternativeLabel {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .textGreyy{
    color: $textColor;
  }

  


}
  .accordin-css div{
    padding: 4px 12px !important;
  }


.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed,
.css-4ff9q7.Mui-completed {
  color: $boxColor !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-error {
  color: #d32f2f !important;
}
.css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel{
  font-size:12px !important;
}
@media (max-width: 1024px) {
  .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel,
  .css-farnub.MuiStepLabel-alternativeLabel {
    font-size: 12px !important;
  }
}
@media (max-width: 375px) {
  .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px;
  }
}


.divTrackingLink{
  display:flex;
}

.logoTracking{
  padding-right:10px;
}
.linkTracking{
  font-weight:bold;
  color:#06c;
}

.produktName{
  font-weight:bold !important;
}