.maxWidth {
    max-width: 1200px;
    padding: 0px 20px;
}

.bth {
    margin-right: 2vw;
    line-height: 1.6;
    padding: 4px 5px;
    font-weight: 400;
    border: 2px solid #06c;
    color: #06c;
    font-size: 16px;
}
.bth:hover {
    text-decoration: underline;
}