@import "../../../../theme/colors/index.scss";
@import "../../../../theme/fonts/index.scss";

.hardware {
  h1 {
    font-size: $fontSizeForSubHeading;
    font-weight: $fontWeightForSubHeading;
  }
  .steppers {
    margin: 12px 0px;
    margin-bottom: 28px;
    background-color: $mainBgColor;
    border-radius: 8px;
    padding: 16px 0px !important;
    .contents div {
      padding: 8px 16px !important;
      
    }
  }
  .warningClass {
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-error,
    .css-qivjh0-MuiStepLabel-label.Mui-error,
    .warn-msg,
    .css-farnub.Mui-error {
      color: #d32f2f !important;
    }
  }
  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
  .css-4ff9q7.Mui-active {
    color: #55c85a !important;
  }
  .failedClass {
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-error,
    .css-4ff9q7.Mui-error {
      color: #d32f2f !important;
    }
  }
  // .css-117w1su-MuiStepIcon-text{
  //   display: none;
  // }
  .nested_card,
  .accor_drop {
    margin: 12px 0px;
    background-color: $mainBgColor;
    border-radius: 8px;
    padding: 16px 0px;
    h1 {
      font-weight: $fontWeightForText;
    }
    p {
      color: $textColor;
      padding: 2px 12px;
    }
  }
  .accor_drop {
    display: none;
  }
  .acc-active {
    display: block;
    margin: 12px 0px;
    background-color: $mainBgColor;
    border-radius: 8px;
    padding: 16px 0px;
    h1 {
      font-weight: $fontWeightForText;
    }
    p {
      color: $textColor;
      padding: 2px 12px;
    }
  }
  .buttondrop{
    background-color: $mainBgColor !important;
    margin-top: -8px;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type,
  .css-67l5gl:last-of-type {
    width: 100% !important;
  }
}
.accordins {
  display: none !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: $boxColor !important;
}
.description {
  color: $textColor;
  margin-bottom: 18px !important;
}
@media (max-width: 642px) {
  .nested_card {
    display: none !important;
  }

  // accordin CSS Start
  .accordins {
    display: inline-block !important;
  }
  .contents div,
  .css-15v22id-MuiAccordionDetails-root {
    padding: 4px 0px !important;
    margin-top: -14px;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root,
  .hardware .css-67l5gl:last-of-type {
    border: none !important;
    background-color: $mainBgColor !important;
  }
  .css-o4b71y-MuiAccordionSummary-content {
    margin: 0px !important;
  }
  .css-15v22id-MuiAccordionDetails-root,
  .css-u7qq7e {
    background-color: $white !important;
  }
  .css-u7qq7e {
    padding: 8px 0px 16px !important;
  }
  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 54px !important;
  }
  .css-15v22id-MuiAccordionDetails-root {
    outline: white !important;
    outline-width: 4px !important;
    outline-style: solid !important;
  }
  .hardware .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type,
  .hardware .css-67l5gl:last-of-type {
    outline: white !important;
    outline-style: solid !important;
    outline-width: 2px !important;
    border-radius: 8px !important;
  }
  .css-yw020d-MuiAccordionSummary-expandIconWrapper,
  .css-1fx8m19 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .css-i4bv87-MuiSvgIcon-root,
  .css-vubbuv {
    font-size: 3rem !important;
  }
  .accordin-css div {
    padding: 4px 12px !important;
  }
  // End of CSS
}

@media (max-width: 375px) {
  .css-qivjh0-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px;
  }
}

.noPrintsText{
  padding-top: 50%;
    text-align: center;
    color: #bec1d0;
}