@import "../../../theme/colors/index.scss";
@import "../../../theme/fonts/index.scss";

.auth {
  .card {
    background-color: $white;
    width: 500px;
    font-family: $fontFamily;

    h1 {
      font-size: $fontSizeForSubHeading;
      text-align: center;
      font-weight: $fontWeightForSubHeading;
    }

    @media (max-width: 538px) {
      width: 100%;
    }
  }
}

.redwarning {
  color: #e30000;
}

.popup-content {
  border-radius: 25px;
  background: #FFF;
  width: auto;
  margin: auto;
  padding: 5px;
  border: 1px solid #d7d7d7
}

.modal {
  font-size: 12px;
  width: 860px;
  height: 698px;
  flex-shrink: 0;
}

.modal>.header {
  color: #000;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 48px;
  padding-left:  51px;
}

.modal>.content {
  width: 765px;
  height: 155px;
  flex-shrink: 0;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 25px;
  padding-left:  51px;
}

.modal>.actions {
  position: absolute;
  bottom: 40px;
  left: 315px;
  text-align: center;
}

.button-close {
  width: 229px;
  height: 47px;
  border-radius: 50px;
  border: 2px solid #06C;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #06C
}




.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  right: 17px;
  top: 13px;
  width: 26px;
  height: 35px;
  flex-shrink: 0;
  //background: #FFF;
}

[role=tooltip].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .16);
  border-radius: 5px
}

.popup-overlay {
  background: rgba(0, 0, 0, .5)
}

[data-popup=tooltip].popup-overlay {
  background: transparent
}

.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}
.link {
  cursor: pointer;
  color:rgb(0, 113, 227)
}