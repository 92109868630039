.footer-main {
    border: none;
    background: white;
    padding-top: 80px;
    padding-bottom: 40px;
    border-top: 1px solid #e5e5e5 !important;
}

.footer-main .footer-columns {
    padding-bottom: 40px;
}

@media (max-width:991.98px) {
    .footer-main .footer-columns {
        padding-bottom: 50px;
    }
}

.footer-main .is-logo-column {}

.footer-main .is-logo-column .logo-container {
    width: 140px;
}

.footer-main .footer-column {
    border: none;
}

.footer-main .footer-column .footer-column-headline {
    margin-bottom: 20px;
    color: #000;
    font-weight: 700;
    font-size: 14px;
}

.footer-main .footer-column .footer-link-item {
    margin-bottom: 5px;
}

.footer-main .footer-column .footer-link,
.footer-main .footer-column .footer-contact-hotline a,
.footer-contact-hotline .footer-main .footer-column a {
    color: #333;
}

.footer-main .footer-column .is-contact-link {
    color: #333;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-bottom: 5px;
}

.footer-main .footer-column .is-contact-link i {
    margin-right: 10px;
}

.footer-main .footer-column .is-contact-link .contact-link {
    color: #333;
}

.footer-main .footer-column .pixlip-socials {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
}

.footer-main .footer-column .pixlip-socials .social-link {
    color: #333;
}

.footer-main .footer-column .pixlip-socials i {
    font-size: 20px;
    color: #333;
}

.footer-main .footer-logos .footer-logo {
    width: 60px;
}

.footer-main .footer-logos .footer-logo img {
    max-width: 100%;
    margin: 0 auto;
}

.footer-main .copyright-column {
    font-weight: 600;
    font-size: 16px;
    padding-left: 0px !important;
}

.container {
    max-width: 1200px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.img-fluid.header-logo-main-img {
    max-width: none !important;
}

img {
    vertical-align: middle;
    border-style: none;
}

.header-logo-main-img {
    height: 25px;
    max-height: 40px;
}


.row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-left: -20px;
}

.col-md-4 {
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;

}

.col-md-2 {
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}

.col-sm-6 {
    -ms-flex: 0 0 50%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}